
// Body
$body-bg: #fff;

// Header
$hd-bg: #D3CCB1;
$hd-hg: 15vh;

// Page Container
$pg-cnt-hg: 100vh - $hd-hg;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;
$font-size-h1: 32px;
$font-color-h1: #757575;
$font-color-h4: #a1a1a1;
// Navbar
$navbar-default-bg: #18202f;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// BOX Shadow
$box-shadow: 0px 0px 10px 2px #eee;
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-center {
  display: block;
  margin: 0 auto;
}
.display-block {
  display: block;
  width: 100%;
  height: 100%;
}
.h1 {
  color: $font-color-h1;
  font-size: $font-size-h1;
}
.btn-default {
  border: none;
  border-radius: 0;
}
.btn-default:focus {
  border: none;
  border-radius: 0;
}