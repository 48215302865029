.row.template-header-row {
  margin: 0;
  background: #546a76;
  color: #fff;
  padding: 10px 0 5px 10px;
}
p.get-selected-date {
  display: inline-block;
  font-size: 18px;
  margin-left: 10px;
  text-transform: capitalize;
}
button.valid-button.valid-button-template {
  width: 210px;
}

button.valid-button.valid-button-template span.button-text {
  display: inline-block;
  width: 155px;
  text-align: center;
  font-size: 15px;
}
.template-positions {
  width: 10px;
  display: inline-block;
  height: 10px;
  color: #333;
}
.template-positions.selected-elements{
  width: auto;
  height: auto;
  font-size: 15px;
  border: none;
  background: #3a465b;
  color: #fff;
  border-radius: 25px;
  padding: 5px 15px;
  margin: 10px;
  cursor: pointer;
  position: relative;
}
.template-positions.selected-elements.disable-element {
  cursor: no-drop;
  background: #eee;
  color: #ccc;
}

.highlight {
  background: #00A000;
}
#html-content-holder{
  margin: 0 auto;
  margin-top: 50px;
}