.page-container-home {
  #adminSections[class^="col-"] {
    height: inherit;
    [class^="col-"] {
      height: inherit;
    }
  }
  #userSections {
    [class^="col-"] {
      height: inherit;
    }
  }
  [class^="col-"] [class^="col-"]{
    height: inherit;
    padding-left: 15px;
    padding-right: 15px;
  }
  height: $pg-cnt-hg;
  [class^="col-"] {
    height: inherit;
    padding: 0;
  }
  nav {
    height: $pg-cnt-hg;
    background: $navbar-default-bg;
    position: relative;
    ul.parent-nav-menu {
      padding-top: 20px;
      li {
        padding: 10px 10px;
        margin-bottom: 15px;
        position: relative;
        cursor: pointer;
        ul.submenu-items {
          margin-left: 35px;
          li {
            margin: 0px;
            .item-img {
              width: 30px;
              height: 30px;
              padding: 6px 7px;
              .fa {
                color: #fff;
                font-size: 15px;
                padding: 1px;
              }
            }
          }
        }
        p {
          display: inline-block;
          color: #7887a5;
          padding-left: 10px;
          font-weight: 700;
        }
        &.logout-item:last-child {
          position: absolute;
          width: 100%;
          bottom: 50px;
        }
        &:hover:not(:last-child),&[aria-expanded="true"] {
          .hover-point {
            display: block;
            width: 37px;
            height: 36px;
            background: url("../images/hover-menu.png") no-repeat;
            position: absolute;
            right: -18px;
            top: 12px;
          }
          p {
            color: #fff;
          }
          background: #3a465b;
        }
        span.item-number {
          position: relative;
          left: -10px;
          top: 6px;
          padding: 2px 10px;
          color: #fff;
          background: #2d3648;
          border-radius: 12px;
          font-weight: bold;
        }
        .client-avatar {
          display: inline-block;
        }
        .client-exit {
          display: inline-block;
          position: relative;
          left: -10px;
        }
        .client-avatar {
          img {
            display: inline-block;
            position: relative;
            top: -11px;
            margin-right: 5px;
          }
          .client-avatar-text {
            display: inline-block;
            span {
              display: block;
              &.client-name {
                color: #fff;
                font-weight: bold;
              }
            }
          }
        }
        .client-exit {
          .exit-login-icon {
            padding: 5px 10px;
            display: block;
            background: #3a465b;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            position: relative;
            top: 5px;
          }
        }
      }
    }
  }
}
.item-img {
  padding: 8px 9px;
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  .fa {
    color: #fff;
    font-size: 20px;
    padding: 1px;
  }
  &.item-img-add-manager {
    background: #3097D1;
  }
  &.item-img-add-element {
    background: #d0bfa4;
  }
  &.item-img-week {
    background: #e03c2a;
  }
  &.item-img-elements {
    background: #f9c71c;
  }
  &.item-img-historic {
    background: #8cbe1e;
  }
  &.item-img-template {
    background: #fe7c00;
  }
  &.item-img-add-admin {
    background: #fe7e31;
  }
}
.valid-button {
  display: block;
  width: 155px;
  margin: 0 auto;
  background: #8cbe1e;
  padding: 5px;
  color: #fff;
  font-weight: bold;
  border-radius: 25px;
  border: none;
  outline: 0;
}
span.button-icon {
  background: #a1d42f;
  padding: 6px 7px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  left: -5px;
}
span.button-text {
  display: inline-block;
  width: 105px;
  text-align: center;
  font-size: 15px;
}
.templates-div {
  display: block;
  margin-top: 50px;
}
#html-content-holder .template-positions.selected-elements {
  background: transparent;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
img.ansamble-logo-img {
  margin: 0 auto;
  display: block;
  margin-top: 15px;
}

.template-positions.selected-elements p {
  margin: 0;
  display: inline-block;
}
.remove-element {
  width: 20px;
  height: 20px;
  background: #dfdfdf;
  color: #474747;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  font-size: 13px;
  padding: 1px 6px;
  top: -10px;
  left: -3px;

}
.remove-element:hover {
  text-decoration: none;
}
#template-container .remove-element{
  display: inline-block;
}

a.btn-menu {
  width: 100%;
  display: block;
  text-decoration: none;
}

#contentPage { display: none;  }
#contentPage { display: block;}
.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("../images/loadpage.gif") center no-repeat #fff;
}

.initial-section {
  overflow: auto;
  max-height: 85vh;

}

.selected-date-name {
  background: #3a465b;
  margin-bottom: 5px;
  padding: 8px;
  border-radius: 20px;
  padding-left: 15px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}

div[id^='submenu-'] {
  padding-left: 10px;
  margin-top: 20px;
  margin-bottom: 5px;
}
span.selected-date-point {
  display: block;
  width: 13px;
  height: 13px;
  position: absolute;
  background: #a1d42f;
  left: -25px;
  top: 15px;
  border-radius: 15px;
  border: 3px solid #8cbe1e;
}
span.selected-elements-name {
  position: absolute;
  z-index: 9;
  color: #3a465b;
  font-weight: bold;
  top: 9px;
  left: 8px;
}
span.select2-selection__placeholder {
  padding-left: 20px !important;
}
.selected-element-content {
  position: relative;
}
span.select2-selection__rendered {
  padding-left: 70px !important;
}
/********************/
div#template-container {
  width: 100%;
  height: 100%;
  position: relative;
}
table#template-container-table {
  position: relative;
  padding-top: 55px;
  padding-left: 20px;
  border-collapse: separate;
  border-spacing: 5px 5px;
  height: 100%;
  padding-bottom: 50px;
}
#template-container-table td {
  background-color: #fff;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  vertical-align: middle;
}
#template-container-table tbody tr:first-child td {
  padding-top: 20px;
}
#template-container-table td.table-elements-name {
  position: absolute;
  background: transparent;
  color: #d12926;
  font-weight: bold;
  border-top: none !important;
  border-radius: 0;
  padding: 0 !important;
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
  transform: rotate(-90deg);
  left: -10px;
  font-family: 'Passion One', cursive;
  text-transform: uppercase;
  font-size: 22px;
  margin-top: 50px;
  margin-left: 2px;
  width: 50px;
}

#template-container-table.table td.table-header-days {
  vertical-align: bottom;
  border-bottom: none;
  padding: 0;
  position: relative;
  top: 32px;
  text-align: center;
  color: #d7c8b3;
  font-weight: 100;
  -webkit-transform: rotate(-7deg);
  transform: rotate(-7deg);
  margin: 0;
  background-color: transparent;
  z-index: 99;
  font-family: 'Passion One', cursive;
  text-transform: uppercase;
  font-size: 22px;
}
.selected-element-close-open {
  color: #93a2c1;
}
.selected-element-close-open i.fa {
  background: #5d6a80;
  padding: 5px 7px;
  border-radius: 50%;
  margin-left: 5px;
  color: #93a2c1;
}

.page-container-home nav ul.parent-nav-menu li:hover:not(:last-child),
.page-container-home nav ul.parent-nav-menu li[aria-expanded="true"],
.page-container-home nav ul.parent-nav-menu li.active {
  background: #3a465b;
}
.page-container-home nav ul.parent-nav-menu li.active p{
  color: #fff;
}
.page-container-home nav ul.parent-nav-menu li:hover:not(:last-child) .hover-point,
.page-container-home nav ul.parent-nav-menu li[aria-expanded="true"] .hover-point,
.page-container-home nav ul.parent-nav-menu li.active .hover-point{
  display: block;
  width: 37px;
  height: 36px;
  background: url(/images/hover-menu.png?e1cb110663b249009564acbd02071191) no-repeat;
  position: absolute;
  right: -18px;
  top: 12px;
}
.user-information {
  display: inline-block;
  margin-left: 10px;
}
header .client-logo .user-info img {
  border: none;
}
header .client-logo .user-info span {
  display: inline-block;
  position: relative;
  left: 7px;
  top: -2px;
  color: #989072;

}
.user-name {
  color: #fff;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}