#adminSections {
  .admin-section {
    margin-top: 50px;
  }

  #pageAdminBg {
    padding: 0;
  }

  .page-admin-bg {
    @extend .display-block;
    background: url("../images/image-login.png") no-repeat;
    background-size: cover;
    width: 100%;
    height: 85vh;
  }

  .new-manager-form {
    @extend .content-center;
    width: 420px;
    padding: 1px 20px;
    box-shadow: $box-shadow;
    border-radius: 10px;
    .new-manager-title {
      @extend .h1;
    }
    .new-manager-text {
      font-weight: 600;
      color: $font-color-h4;
    }
    input[type="file"] {
      margin-top: 15px;
    }
  }

  .show-selected-template {
    margin-bottom: 20px;
  }

  .panel-default-choose-tempale {
    border-color: #e6e5e5;
    width: 400px;
    margin: 0 auto;
    margin-top: 50px;
  }

  .new-user-form {
    @extend .content-center;
    width: 420px;
    padding: 1px 20px;
    box-shadow: $box-shadow;
    border-radius: 10px;
    .new-user-title {
      @extend .h1;
    }
    .new-user-text {
      font-weight: 600;
      color: $font-color-h4;
    }

  }

  .multiselect-native-select .btn-group {
    width: 100%;
  }

  .open > .btn-default.dropdown-toggle,
  .multiselect-native-select .btn-group.open .dropdown-toggle,
  .multiselect-native-select .btn-default:active,
  .multiselect-native-select .btn-default:focus,
  .multiselect-native-select .btn-default:hover {
    color: #a1a1a1;
    background: #fff;
    box-shadow: none;
  }

  button.multiselect.dropdown-toggle.btn.btn-default {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  width: 100%;
  text-align: left;
  }

}