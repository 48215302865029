.calendar-div-bg {
  background-color: #546a76;
  color: #fff;
}
p.show-date-calendar {
  color: #565656;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  border: 1px solid #ced1d2;
  padding: 10px;
  font-size: 16px;
}
.multiple-date-picker {
  box-shadow: $box-shadow;
}
.calendar-div {
  @extend .center-section-home;
}
.picker-day.picker-selected {
  @extend .calendar-div-bg;
}
.picker-top-row {
  @extend .calendar-div-bg;
  padding: 15px;
}
.picker-days-week-row {
  width: 100%;
  @extend .calendar-div-bg;

}
.picker-navigate {
  width: 16.66%;
  background: #98a5ac;
  border-radius: 15px;
  font-size: 16px;
  font-weight: bold;
}
.valid-button.valid-button-calender{
  margin-top: 60px;
}
