.page-container-login {
  height: $pg-cnt-hg;
  .col-md-6 {
    height: $pg-cnt-hg;
    padding: 0;
    @include flex-center;
  }
  .page-login-bg {
    @extend .display-block;
    background: url("../images/image-login.png") no-repeat;
    background-size: cover;
  }
}
.login-form {
  @extend .content-center;
  width: 420px;
  padding: 0px 20px;
  box-shadow: $box-shadow;
  border-radius: 10px;
  .login-page-title {
    @extend .h1;
  }
  .login-page-text {
    font-weight: 600;
    color: $font-color-h4;
  }
}