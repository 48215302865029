.select2-container--default .select2-selection--single {
  background-color: #e2e2e2;
  border: none;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #7f7f7f;
  line-height: 40px;
}
.select2-container {
  width: 380px !important;
  height: 40px;
  margin-bottom: 10px;
}
.select2-container .select2-selection--single {
  height: 40px;
}

.select-elements {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  padding-left: 18px;
  border-left: 1px solid #eee;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #929292;
  font-weight: 700;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px;
  background: #768b97;
  border-radius: 0px 2px 3px 0px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #fff transparent transparent transparent;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #fff transparent;
}
