html, body {
  background-color: $body-bg;
  color: #636b6f;
  font-family: $font-family-sans-serif;
  font-weight: 100;
  height: 100vh;
  margin: 0;
}
header {
  .ansamble-logo {
    padding-top: 10px;
  }
  .client-logo {
    padding: 20px 50px;
    img {
      border: 5px solid rgba(255,255,255,0.5);
      max-width: 90px;
      float: left;
    }
  }
  background: $hd-bg;
  height: auto;
  span {
    display: block;
    color: #fff;
    font-weight: bold;
  }
}
label {
  color: $font-color-h4;
  font-weight: 500;
}
.btn-success {
  color: #fff;
  background-color: #50ca5e;
  border-color: #50ca5e;
}
.form-control {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #d4d4d4;
  border-radius: 0;
}
.form-control:focus {
  border-color: #4a90e2;
  outline: 0;
  box-shadow: none;
}
.option-btn {
  margin-top: 95px;
  .btn-dropdown-left {
    @extend .btn-default;
    background: transparent;
    color: #fff;
    &:focus, &:hover, &:active,
    &:active:hover, &:active:focus{
      @extend .btn-default;
      background: transparent;
      color: #fff;
      outline: 0;
    }
  }
  .dropdown-toggle {
    @extend .btn-default;
  }
}
.btn-default:hover,
.option-btn .btn-dropdown-left:hover,
.option-btn .btn-dropdown-left:hover:active:focus,
.option-btn .dropdown-toggle:hover {
  color: #fff;
  background-color: transparent;
  border-color: none;
  box-shadow: none;
}
.dropdown-menu {
  li {
    margin-bottom: 10px;
    a {
      color: $font-color-h4;
      font-weight: 600;
      img {
        margin-right: 15px;
      }
    }
    button {
      color: $font-color-h4;
      font-weight: 600;
      img {
        margin-right: 15px;
      }
    }
  }
}
.center-section-home{
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
}
.dropdown-menu > li > button {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.6;
  color: #333333;
  white-space: nowrap;
}
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  background: transparent;
}
/*apply colors if not selected, if selected I want the default selected css*/
.picker-day.haveTemplate:not(.picker-selected){
  background-color: #00ffa2;
  color: #fff;
}

/*if my holiday isn't clickable (it's the case in the demo)*/
.picker-day.haveTemplate.picker-off{
  background-color: #00a267;
  color: #fff;
  cursor: default;
}